import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import HeaderUnderlined from "../components/HeaderUnderlined";
// import PlatformIcon from "../components/PlatformIcon";
import Layout from "../components/layout";
import Seo from "../components/Seo";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import {
  AiOutlineFieldTime,
  AiOutlineSmile,
  AiOutlineFileAdd,
} from "react-icons/ai";

const readingTime = require("reading-time");

const ArticleTemplate = ({ data, pageContext }) => {
  const next = pageContext.next;
  const previous = pageContext.previous;
  const {
    title,
    body,
    relationships,
    field_image,
    field_upper_image,
    field_footer_images,
    created,
  } = data.allNodeArticle.nodes[0];

  // image consts
  const thumbnail = getImage(
    relationships.field_image.localFile.childImageSharp
  );
  const upperImage = getImage(
    relationships.field_upper_image.localFile.childImageSharp
  );
  const footerPictures = relationships.field_footer_images;
  const tags = relationships.field_tag;
  const { name: writer, path: writerPath } = relationships.field_writer;

  // reading time
  const readTime = readingTime(body.processed.replace(/<[^>]*>?/gm, ""));
  return (
    <>
      <Layout>
        <Seo title={title} />

        <Container fluid="lg">
          <Row>
            <Col xl={3} lg={3} md={4} sm={6} xs={12} className="wavebackground">
              <div>
                <GatsbyImage image={thumbnail} alt={field_image.alt} />
              </div>
            </Col>
            <Col className="p-5 bg-white">
              <HeaderUnderlined titlesrc={title} />
              <div className="pb-2">
                <span>
                  <AiOutlineSmile className="react-icons mr-2" />
                </span>
                <span className="font-weight-bold">Автор: </span>
                <span>
                  {/* <a
                      href={writerPath.alias}
                      target="_blank"
                      rel="noopener noreferrer"
                    > */}
                  {writer}
                  {/* </a> */}
                </span>
              </div>
              <div className="pb-2">
                <span>
                  <AiOutlineFieldTime className="react-icons mr-2" />
                </span>
                <span className="font-weight-bold">Время чтения: </span>
                <span>
                  <b>{Math.ceil(readTime.minutes)}</b> мин. (
                  <b>{readTime.words}</b> слов).
                </span>
              </div>
              <div className="pb-2">
                <span>
                  <AiOutlineFileAdd className="react-icons mr-2" />
                </span>
                <span className="font-weight-bold">Дата: </span>
                <span>
                  <b>{created}</b>
                </span>
              </div>
              <Alert variant="success">
                <span>{body.summary}</span>
              </Alert>
              <GatsbyImage image={upperImage} alt={field_upper_image.alt} />
              <div
                dangerouslySetInnerHTML={{ __html: body.processed }}
                className="article"
                style={{ paddingTop: "1rem" }}
              />
              {footerPictures.map((footerPicture, index) => {
                const footerPic = getImage(footerPicture.localFile);
                return (
                  <div key={index} className="flex text-center">
                    <GatsbyImage
                      image={footerPic}
                      alt={field_footer_images[index].alt}
                    />
                    <p className="text-center font-weight-bold">
                      {field_footer_images[index].title}
                    </p>
                  </div>
                );
              })}
              <hr />
              <div className="pb-2">
                {tags.map((tag) => {
                  return (
                    <Link to={tag.path.alias} key={tag.id}>
                      <Badge className="badge" variant="info">
                        {tag.name}
                      </Badge>
                    </Link>
                  );
                })}
              </div>
              <hr />
              <Container className="py-3">
                <Row>
                  <Col>
                    {previous && (
                      <Link
                        to={previous.path.alias}
                        rel="prev"
                        className="font-weight-bold"
                      >
                        <FaArrowLeft className="react-icons" /> {previous.title}
                      </Link>
                    )}
                  </Col>
                  <Col className="text-right">
                    {next && (
                      <Link
                        to={next.path.alias}
                        rel="next"
                        className="font-weight-bold"
                      >
                        {next.title} <FaArrowRight className="react-icons" />
                      </Link>
                    )}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};
export const query = graphql`
  query ($articlePageId: String!) {
    allNodeArticle(filter: { id: { eq: $articlePageId } }) {
      nodes {
        id
        path {
          alias
        }
        title
        body {
          processed
          summary
        }
        field_image {
          alt
        }
        field_upper_image {
          alt
        }
        field_footer_images {
          alt
          title
        }
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 70
                  width: 500
                  height: 500
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          field_tag {
            id
            name
            path {
              alias
            }
          }
          field_upper_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 70
                  width: 800
                  height: 200
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          field_footer_images {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
          field_writer {
            name
            path {
              alias
            }
          }
        }
        created(formatString: "DD-MM-YYYY")
      }
    }
  }
`;
export default ArticleTemplate;
